<!-- DNA定量分析系统  -->
<template>
  <div id="dnaProd">
    <Header></Header>
    <div class="dnaProd-img">
       <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/product-detail/programme-3.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          DNA定量分析系统
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>快速</li>
          <li>简洁</li>
          <li>精准</li>
          <li>高效</li>
        </ul>
      </div>
    </div>
    <div class="dnaProd-description">
      <div class="parameter">
        <img
          class="wow animate__animated animate__fadeInUp"
          src="@/assets/image/dnaProd/parameter.png"
          alt=""
        />
      </div>
      <div class="characteristic">
        <ul class="feature-box">
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">染色全</div>
              <div class="desc">一片双染，一个样本、一张玻片、一次扫描、两张（DNA+TCT）报告</div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">效率高</div>
              <div class="desc">
                DNA染色最快仅需15分钟，扫描+识别时间≤2分钟
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">检出准</div>
              <div class="desc">
                全片扫描、全片识别，充分发现每一个异常细胞。
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">警示早</div>
              <div class="desc">
                相比细胞形态学更早发现癌前病变
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">应用广</div>
              <div class="desc">
                可用于宫颈癌、口腔癌、肺癌等肿瘤疾病的早期筛查。
              </div>
            </div>
          </li>
           <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">开展易</div>
              <div class="desc">
                对阅片专业要求相对较低，非常适合基层医院开展。
              </div>
            </div>
          </li>
           <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">结果全</div>
              <div class="desc">
                一片双染，一次采样，一次检测，可提供TCT+DNA两份报告。
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "产品",
      productName: "DNA定量分析系统",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
          path: "/product/special",
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;

              console.log(this.relatProd);
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#dnaProd {
  padding-top: 6.5rem;
  .dnaProd-img {
    img {
      width: 100%;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 22%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .dnaProd-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    // background-image: url("../../../../assets/image/product-detail/des-bg.png");
    background-size: cover;

    .parameter {
      width: 100%;
      margin: 1rem 0 1rem;
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }

    .characteristic {
      margin: auto;
      .feature-box {
        .feature-item {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 3rem;
          .left {
            width: 15%;
            margin-right: 5%;
            img {
              padding-top: 0.4rem;
              width: 100%;
            }
          }
          .right {
            width: 80%;
            .title {
              font-size: 1.2rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .desc {
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
      }
    }
    .film-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 28%;
        margin-right: 20%;
        margin-bottom: 1.5rem;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          margin-bottom: 0.8rem;
        }
        .text {
          font-size: 0.875rem;
          text-align: center;
          font-family: "SourceHanSansSC-Medium";
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>